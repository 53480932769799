import $ from "jquery";
import { logFactory, isCompExists } from "GlobalSite";

// Component name
const compName = "comp_tech_generic-modal";
const Log = logFactory(compName);
const { log, error } = Log;

// Selectors
const SELECTORS = {
  DIALOG: ".dialog",
  get DIALOG_OPEN() {
    return `${this.DIALOG}[open]`;
  },
  get CLOSE_BUTTON() {
    return `${this.DIALOG} .dialog-close`;
  },
  DIALOG_CONTAINER: ".dialog-container",
};

// Events
const EVENTS = {
  CLICK: "click",
  KEYDOWN: "keydown",
};

// Keys
const KEYS = {
  ESCAPE: "Escape",
};

log("--->");

// Function to open dialog
export function openDialog($dialog) {
  const dialog = $dialog[0];
  if (typeof dialog.showModal === "function") {
    dialog.showModal();
    // Set focus on close button when dialog opens
    $dialog.find(SELECTORS.CLOSE_BUTTON.replace(SELECTORS.DIALOG, "")).focus();
  } else {
    error("L'API dialog n'est pas supportée par ce navigateur");
  }
}

// Function to close dialog
export function closeDialog($dialog) {
  if ($dialog.length) {
    $dialog[0].close();
  }
}

$(function domReady() {
  if (!isCompExists(compName, Log)) {
    return;
  }

  $(document)
    // Close dialog when clicking close button using event delegation
    .on(EVENTS.CLICK, SELECTORS.CLOSE_BUTTON, function () {
      log("Close button clicked");
      closeDialog($(this).closest(SELECTORS.DIALOG));
    })
    // Close dialog when clicking outside using event delegation
    .on(EVENTS.CLICK, SELECTORS.DIALOG, function (event) {
      if (event.target === this) {
        const $dialog = $(this);
        const offset = $dialog.offset();
        if (
          event.pageX < offset.left ||
          event.pageX > offset.left + $dialog.outerWidth() ||
          event.pageY < offset.top ||
          event.pageY > offset.top + $dialog.outerHeight()
        ) {
          closeDialog($dialog);
        }
      }
    })
    // Close dialog when pressing Escape key
    .on(EVENTS.KEYDOWN, function (event) {
      if (event.key === KEYS.ESCAPE) {
        const $openDialog = $(SELECTORS.DIALOG_OPEN);
        $openDialog.length && closeDialog($openDialog);
      }
    })
    // Stop propagation for clicks inside dialog content
    .on(EVENTS.CLICK, SELECTORS.DIALOG_CONTAINER, (event) => {
      event.stopPropagation();
    });
});
